import { getRGBAValue } from "@/Utils/Utils";
import React, { Component } from "react";
// import { Box } from "@mui/material/Box";

import CustomBox from "./CustomBox";

class BoxIcon extends Component<{
  icon: any;
  color: string;

  width?: string;

  unchecked?: boolean;
  onClick?: any;
}> {
  render() {
    return (
      // <div
      // style={{
      //   display: "inline-block"
      // }}
      // onClick={this.props.onClick ? this.props.onClick : null}>
      <CustomBox
        onClick={this.props.onClick ? this.props.onClick : null}
        style={{
          width: this.props.width ? this.props.width : "32px",
          height: "20px",
          display: "inline-block",
          //   position: this.props.unchecked ? "absolute" : "relative",

          backgroundColor: this.props.unchecked
            ? "transparent"
            : getRGBAValue(this.props.color, 1),
          borderRadius: "4px",

          padding: this.props.unchecked ? "1px" : "3px",
          // paddingTop: "10px",
          margin: "2px",
          color: "#ffffff",
          textAlign: "center",
          justifyContent: "center",

          //   right: "0px",
          //   bottom: "10px",

          //   position: this.props.unchecked ? "absolute" : "relative",
        }}
        sx={{
          border: this.props.unchecked ? 2 : 0,
          borderColor: this.props.unchecked ? this.props.color : "",
        }}
      >
        {this.props.icon}
      </CustomBox>
      // </div>
    );
  }
}

export default BoxIcon;
